import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './ladderout.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Resume from '../components/resume';
import VideoCase from '../components/video';
// other
import { meta } from '../../../metaData';

{
  /*  */
}

function LadderOutCase() {
  const data = useStaticQuery(graphql`
    query {
      screen1: file(relativePath: { eq: "ladderout-screen1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "ladderout-screen2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "ladderout-screen3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "ladderout-screen4.png" }) {
        childImageSharp {
          fluid(maxWidth: 431, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "ladderout-screen5.png" }) {
        childImageSharp {
          fluid(maxWidth: 431, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "ladderout-screen6.png" }) {
        childImageSharp {
          fluid(maxWidth: 431, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "ladderout-screen7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "ladderout-screen8.png" }) {
        childImageSharp {
          fluid(maxWidth: 1018, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen9: file(relativePath: { eq: "ladderout-screen9.png" }) {
        childImageSharp {
          fluid(maxWidth: 626, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ladderOut: file(relativePath: { eq: "career/ladder-out.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      isRed
      score={['HealthTech', 'Sweden', 'April, 2018', 'Finished']}
      navBtn="#A0E4FB"
      layoutClassName={styles.main}
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Developing LadderOut project to help cope with porn addiction"
      subTitle="How Brocoders created an online solution to help curb a shame cycle, restore authentic human connections and live life to the fullest with confidence in the future."
      metaData={meta.ladderout}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          LadderOut is a mental health coaching software to support people who require additional
          help in breaking free from adult content use.
          <br />
          <br />
          LadderOut offers a program that can help with research-based information, interactive
          videos and skill-building activities, thus covering the most crucial aspects of the
          porn-quitting process.
        </p>
        <p className={itemStyles.paragraph}>
          Hani Sultan is a serial business owner from Sweden. He launched a startup with his own
          money to help people overcome their porn addiction as well as fully recover right after.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          Thanks to the rapidly evolving World Wide Web, the pornography industry experienced a boom
          back in the 1990s. It made it possible for people to access pornographic material with a
          lesser risk of exposure than ever before, resulting in an entirely new type of marketplace
          and a tremendous increase in both the supply and demand.
        </p>
        <p className={itemStyles.paragraph}>
          The increased availability and consumption of pornography have caused a lot of people,
          whether that be worried parents, physicians or psychiatrists, to notice the mental damage
          that pornography can do to an individual. Even though there’s no official medical
          diagnosis for a porn addiction yet, it doesn’t mean that pornography is harmless.
        </p>
        <p className={itemStyles.paragraph}>
          There are a lot of research studies confirming that people activate powerful and
          mood-altering chemicals that can rewire their minds when they watch pornography. People
          start craving it more than normal human connections, and they’ve come to believe they are
          flawed and unworthy of love eventually.
        </p>
        <p className={itemStyles.paragraph}>
          In fact, numerous researches suggest that people who describe themselves as having a
          problem with pornography have a lot in common with people with drug or alcohol addictions.
          There are a bunch of projects devoted to the struggle against this severe issue.
        </p>
        <p className={itemStyles.paragraph}>
          That said, Hani Sultan decided to create a new project that would allow for effective
          treatment and control of pornography addiction thanks to anonymous tests and quizzes.
          After reviewing a few candidates, Hani Sultan reached out to the Brocoders team to carry
          out his project.
        </p>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Working process</h2>
        <p className={itemStyles.paragraph}>
          To begin with, Hani Sultan has invited a seasoned psychologist to put together a treatment
          program that was then transformed into a step-by-step recovery plan. Once this was done,
          the client came to us with a document that highlighted the sketch projects. To consider
          every crucial detail and come up with an efficient development strategy, we started with
          collaborative workshops.
        </p>
        <p className={itemStyles.paragraph}>
          Following the client’s visions and requirements, it was decided to create a web-driven
          product from scratch, including both the design and the admin panel. Thus we chose Ruby on
          Rails for the backend and React.js for the frontend. To overcome the challenges above and
          push on to the next level, we proceeded to prototype the idea with the UXpin tool.
        </p>
      </div>
      <Img fluid={data.screen7.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Based on recent studies, people who tend to watch pornography are too overloaded with
          information, and it’s hard for them to concentrate on the inputs, lectures, quizzes and
          videos. It was the ground-breaking reason why we decided to opt for a simple and
          minimalistic design in order to help people concentrate on the program rather than
          distracting animation elements.
        </p>
        <p className={itemStyles.paragraph}>
          Once the website design, along with prototypes, were approved by the client, we moved on
          to the development phase. That said, the decision was made to divide the entire
          development process into four stages in order to go through every crucial detail and keep
          the sight of the big picture:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Workshops</li>
          <li>Prototyping</li>
          <li>Design</li>
          <li>Development</li>
        </ul>
        <h2 className={itemStyles.title}>Our team</h2>
        <p className={itemStyles.paragraph}>
          To exceed the client’s expectations, we assembled a proactive team of on-site seasoned
          professionals with years of software development experience. Our specialists examined the
          ins and outs of the current business issue to let people stay on top of their mental
          health.
        </p>
        <p className={itemStyles.paragraph}>
          They demonstrated their ability to meet tight deadlines while responding to the
          ever-changing business requirements without sacrificing the product quality. The team was
          comprised of:
        </p>
        <VideoCase image={data.ladderOut.childImageSharp.fluid} source="ladderout_min" />
        <Team
          color="#ABEBFF"
          list={[
            { title: 'Frontend\ndeveloper', count: 1 },
            { title: 'Backend\ndeveloper', count: 1 },
            { title: 'UI/UX\ndesigner', count: 1 },
            { title: 'Project\nmanager', count: 1 },
          ]}
        />
        <Resume
          services={['product', 'mvp', 'webDev']}
          technology={['react', 'rails', 'blockchain', 'figma', 'reactNative', 'nodejs']}
        />
      </div>
      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Key challenges</h2>
        <p className={itemStyles.paragraph}>
          It’s worth mentioning that the psychologist was continuously working on the recovery
          program all the way from the development stage and after the product launch. Due to this
          fact, it was necessary to introduce amendments to the existing program while ensuring all
          the changes would have no impact on the users’ progress. Backed by their rich expertise in
          the software development field, our specialists managed to successfully cope with these
          tasks.
        </p>
        <h2 className={itemStyles.title}>Delivered product</h2>
        <p className={itemStyles.paragraph}>
          Brocoders helped bring to life an innovative idea and delivered a client-oriented solution
          designed to aid people in breaking the cycle of pornography use. Driven by full-fledged
          research studies, we have ended up with a highly efficient model that helps people quickly
          recover and return to a healthy life.
        </p>
        <p className={itemStyles.paragraph}>
          This project has two types of users: a user who wants to break free from pornography
          addiction and a support person, such as a parent, a friend or a psychologist invited to
          the system by the current user.
        </p>
        <p className={itemStyles.paragraph}>
          There are four steps to sign up on the platform: users enter their name, gender, age and a
          personal goal ─ the reason why they decided to sign up for the program in the first place.
          All the information is safely stored on their profile where they can change it,
          personalize the program, delete the account and invite the support person.
        </p>
        <p className={itemStyles.paragraph}>
          After the sign-up, users are invited to fill in the information about themselves, which is
          essential for their recovery. They will be given access to eight main modules of the
          program and three additional ones.
        </p>
        <p className={itemStyles.paragraph}>
          As mentioned above, the Ladder program has eight modules designed for the personalized
          educational content, various activities and missions to complete, whether that be
          lectures, quizzes, questions, presentations or videos. The modules are meant for the
          estimation of multiple factors:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Triggers</li>
          <li>Blockers</li>
          <li>Breakers</li>
          <li>Motivators</li>
          <li>Stress level</li>
          <li>Current values</li>
        </ul>
        <p className={itemStyles.paragraph}>
          My Recovery Plan is the main page for users, where they can see goals as well as all the
          necessary information that is automatically filled out during the passage of modules. Once
          a user completes his module missions, the recovery plan page is updated with new data.
        </p>
        <p className={itemStyles.paragraph}>
          Backed by such a helpful tool, users can easily monitor their progress, identify their
          triggers, set clear goals and come up with life-changing strategies to overcome their
          issues. Moreover, there are three additional functionality modules:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Relapse module</li>
          <li>Skills</li>
          <li>Journal</li>
        </ul>
      </div>
      <div className={styles.imgContainer}>
        <div className={clsx('inner', styles.ingGroup)}>
          <Img fluid={data.screen4.childImageSharp.fluid} className={styles.screen4} alt="" />
          <Img fluid={data.screen5.childImageSharp.fluid} className={styles.screen5} alt="" />
          <Img fluid={data.screen6.childImageSharp.fluid} className={styles.screen6} alt="" />
        </div>
      </div>
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          In the Journal module, the user can add his own thoughts throughout the period of
          recovery. Also, users can find important parts of the lectures in the Journal.
        </p>
        <p className={itemStyles.paragraph}>
          In the Skills section, a user can identify specific skills he would like to gain during
          the program. Users can either choose between a set of preselected skills or add their own
          unique skills that would help them out with the addiction.
        </p>
        <p className={itemStyles.paragraph}>
          There is also a possibility for users to reset the recovery clock in case they have lost
          control and watched pornography. Then the Relapse module appears on the dashboard, helping
          the user come back to the program and analyze why he has watched adult videos during the
          program.
        </p>
        <p className={itemStyles.paragraph}>
          On top of everything else, here are the most vital features that users can enjoy while
          sticking to the LadderOut solution:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            Thanks to a professional psychologist, users can select a program supplied with a wide
            variety of materials: the very best practices in brain science, psychology and addiction
            treatment.
          </li>
          <li>
            The project implies a support system when a user can invite any support person who would
            be able to analyze his progress.
          </li>
          <li>Users can make their own color customization to enable comfier work.</li>
          <li>
            Users have access to resources (favorites, documentaries, videos and TED talks on
            recovery, saved infographics and visual aids, links to additional research and articles,
            etc.).
          </li>
        </ul>
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={clsx(itemStyles.inner, itemStyles.lastItem)}>
        <h2 className={itemStyles.title}>The results</h2>
        <p className={itemStyles.paragraph}>
          With Brocoders’ help, the client managed to deliver a revolutionary custom-designed
          product that has demonstrated superior quality and stability of the internal recovery
          program. To date, there are more than 1560 active users on the platform. Porn is hard to
          quit, but together with the LadderOut solution, it is now possible to acquire a great
          helping hand to break bad habits for good.
        </p>
      </div>
    </Layout>
  );
}

export default LadderOutCase;
